.spec_item {
  padding: 2.5em;
}

.spec_icon {
  width: 4em;
  margin: auto;
}

.spec_icon_bg {
  width: 4em;
  height: 4em;
  background-color: var(--accent_color_main);
  border-radius: 100%;
  margin: auto;
  padding: 2em;
}

.spec_title {
  padding: 0.8em 0em;
}
