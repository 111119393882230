/* Portfolio */

.port_section {
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.port_title {
  text-align: center;
}

.port_title_underline {
  background-color: var(--accent_color_secondary);
  padding: 0.35em;
  width: 50%;
  margin-bottom: 4em;
}

.port_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.proj_box_img {
  z-index: -2;
  position: relative;
  background-size: 49%;
  background-repeat: no-repeat;
  padding: 50%;
  margin-bottom: -100%;
  /* margin-right: 100%; */
  /* left: 52%; */
  overflow: hidden;
  display: none;
}

.port_item {
  padding: 2em 0em;
  width: 70%;
  margin: auto;
  background: white;

  border-style: solid;
  border-radius: 1.5em;
  border-width: 0.15em;

  filter: drop-shadow(13px 14px 10px rgb(0 0 0 / 25%));
  transition: 0.3s;
}

.proj_container:hover .port_item {
  transform: translateY(-5%) translateX(-5%);
  filter: drop-shadow(17px 14px 3px rgba(0, 0, 0, 0.25));
}

.proj_title_container {
  display: flex;
  padding: 1.5em 0em;
}

.port_accent_container {
  flex: 1;
}

.port_accent-main {
  position: relative;
  right: 0em;
  top: 0%;
  display: inline-block;

  padding: 30% 15%;

  background-color: var(--accent_color_main);
  box-shadow: 13px 14px 10px rgba(0, 0, 0, 0.25);
  transform: rotate(135deg);
}

.port_accent-secondary {
  position: relative;
  right: 0em;
  top: 0%;
  display: inline-block;

  padding: 30% 15%;

  background-color: var(--accent_color_secondary);
  box-shadow: 13px 14px 10px rgba(0, 0, 0, 0.25);
  transform: rotate(135deg);
}

.proj_title {
  padding: 1em 1.1em;
  margin-left: 6.5%;
  letter-spacing: 0.15em;
  flex: 2;
}

.proj_text {
  padding: 1.5em;
  padding-top: 2.5em;
}

.proj_animation_left {
  box-shadow: inset 0 0 40px 40px white;
  width: 100%;
  height: 100%;
  z-index: -2;
  position: absolute;
  top: 50px;
  right: -50%;
}
.proj_animation_right {
  box-shadow: inset 0 0 40px 40px white;
  width: 100%;
  height: 100%;
  z-index: -2;
  position: absolute;
  top: 0;
  left: -50%;
}

.proj_img_container {
  display: flex;
  justify-content: center;
  margin: 1em;
}

.proj_img {
  width: 75%;
  height: auto;
  box-shadow: 13px 14px 10px rgba(0, 0, 0, 0.25);
}

.proj_img_tab {
  background-image: url('../../imgs/whiteblacktiny.png');
  background-size: cover;
  border-radius: 0px 0.5em 0.5em 0px;
  box-shadow: 13px 14px 10px rgba(0, 0, 0, 0.25);
  width: 10%;
}

.port_divider {
  background-color: var(--accent_color_secondary);
  padding: 0.35em;
  margin: 4em;
}

.proj_links {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
}

a {
  color: black;
}

@media only screen and (min-width: 600px) {
  .port_container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .proj_container {
    flex: 50%;
  }

  .proj_container:nth-child(3) {
    position: relative;
    top: -50px;
  }

  .port_right {
    margin-top: 7em;
    margin-left: 7%;
  }

  .port_left {
    margin-right: 7%;
  }

  .port_divider {
    display: none;
  }
}

@media only screen and (min-width: 732px) {
  .proj_title {
    font-size: 1.4em;
  }
}

@media only screen and (min-width: 1100px) {
  .port_title_underline {
    margin-bottom: 9em;
  }

  .proj_text {
    padding-left: 4em;
    padding-right: 4em;
    padding-bottom: 2em;
    padding-top: 4em;
  }
}
