/* Fonts */

@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-size: 16px;
  src: url('./fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-size: 16px;
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-size: 16px;
  src: url('./fonts/Poppins/Poppins-Light.ttf');
}

/* Variables */

:root {
  --accent_color_main: #10e9ee;
  --accent_color_secondary: #ffda01;
}

/* Typography */

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 1.5rem;
}

/* General */

html {
  box-sizing: border-box;
  /*font-family: 'Poppins Regular';*/
  font-family: 'Poppins Light';
  font-size: 1rem;
  color: black;
  /*max-width: 100%;*/
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
}

html,
body {
  height: 100%;
}

canvas {
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

h1,
h2,
h3,
h4,
ul,
p {
  margin: 0;
}

p {
  margin-bottom: 0.85em;
}

img {
  display: block;
  max-width: 100%;
}

b {
  font-family: 'Poppins SemiBold';
}

@media only screen and (min-width: 973px) {
  html {
    font-size: 18px;
  }
}
