.about_me_section {
  padding: 1.5em;
  margin-bottom: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_me_title {
  text-align: center;
}

.about_me_text {
  padding: 3em;
}

@media only screen and (min-width: 600px) {
  .about_me_section {
    padding: 10%;
    margin-bottom: 0em;
  }
}

@media only screen and (min-width: 732px) {
  .about_me_section {
    padding: 7em;
  }
}

@media only screen and (min-width: 1100px) {
  .about_me_section {
    width: 50%;
    margin: auto;
    padding-bottom: 10em;
  }
}
