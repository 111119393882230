.hero_section {
  display: flex;
  flex-flow: column wrap;
}

.hero_texts {
  flex: 1;
  padding: 5em 3.5em;
}

.hero_accent_box {
  background-color: var(--accent_color_main);
  padding: 1em;
}

.hero_animation {
  flex: 1;
  z-index: -2;
  padding-top: 80%;
  /* height: 100vh; */

  background-image: url('../../imgs/whiteblacktiny.png');
  background-attachment: fixed;
  background-size: cover;

  margin: 1em;
  outline-style: solid;
  outline-color: var(--accent_color_main);
  outline-width: 1em;
}

@media only screen and (min-width: 600px) {
  .hero_section {
    flex-flow: row;
    height: 100vh;
  }

  .hero_accent_box {
    padding: 3% 4%;
    position: absolute;
    top: 5%;
    left: 5%;
  }

  .hero_texts {
    flex: 1;
    margin: auto;
    padding: 0em;
  }

  .hero_texts p {
    padding: 0 15%;
  }

  .hero_texts h1 {
    padding: 0 15%;
  }

  .hero_accent_box {
    position: absolute;
  }

  .hero_animation {
    padding-top: 0;
  }
}

@media only screen and (min-width: 732px) {
  .hero_texts {
    min-width: 0;
    min-height: 0;
    overflow: hidden;
  }
}
